//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import buyerSignUrl from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerSignUrl";
import { version } from "moment";

export default {
  name: "contentBox",
  data() {
    return {
      detail: {}
    };
  },
  methods: {
    look_pdf() {
      window.open(this.detail.url, "_blank");
    }
  },
  created() {
    buyerSignUrl().then(res => {
      console.log(res);
      this.detail = res;
    });
  }
};
